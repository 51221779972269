<script lang="ts" setup>
import type { ActionListItem } from '~/components/nrjx/list/action.vue';

// Composables
const { t } = useI18n();

const actions: ActionListItem[] = [
  {
    title: t('global.clients'),
    to: '/admin/clients',
    icon: 'User',
    iconForeground: 'text-blue-700',
    iconBackground: 'bg-blue-50',
    description: t('admin.clients_description'),
  },
  {
    title: t('global.sites'),
    to: '/admin/sites',
    icon: 'Building',
    iconForeground: 'text-orange-700',
    iconBackground: 'bg-orange-50',
    description: t('global.sites_description'),
  },
];
</script>
<template>
  <ui-page :title="$t('global.admin')">
    <nrjx-list-action :actions="actions" />
  </ui-page>
</template>
